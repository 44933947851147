/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202409062254-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqSystemApiClass {
 
   constructor() {}

  /**
   * @summary Create an add-on tool
   */
  public createAddOnTool(
    body: models.AddOnToolInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/system/configuration/addOnTools`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AddOnToolOutputV1>;
  }

  /**
   * @summary Delete an add-on tool
   * @param {string} id - ID of the add-on to delete
   */
  public deleteAddOnTool(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/system/configuration/addOnTools/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Disable a licensed feature until the next server shutdown.
   * @param {string} name - The name of the licensed feature
   */
  public disableLicensedFeature(
    {
      name
    } : {
      name: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(name)) {
      throw new Error("'name' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/system/license/features/${encodeURIComponent(String(name))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Re-enable a licensed feature that was previously disabled. If the feature does not have a valid license or was not previously disabled, this will do nothing.
   * @param {string} name - The name of the licensed feature
   */
  public enableLicensedFeature(
    {
      name
    } : {
      name: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(name)) {
      throw new Error("'name' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/system/license/features/${encodeURIComponent(String(name))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Gets a list of all add-on tools.
   */
  public getAddOnTools(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/system/configuration/addOnTools`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.GetAddOnToolsOutputV1>;
  }

  /**
   * @summary Get the system administrator's contact information
   */
  public getAdministratorContactInformation(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/system/contactInformation`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AdministratorContactInformationV1>;
  }

  /**
   * @summary Used to retrieve the value of the Seeq server agent.key
   */
  public getAgentKey(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/system/agentKey`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AgentKeyOutputV1>;
  }

  /**
   * @summary Used to determine the precise revision of this Seeq build
   */
  public getBuildInfo(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/system/build-info`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ServerBuildInfoOutputV1>;
  }

  /**
   * @summary Get the list of configuration options and their details, including the current and default value
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getConfigurationOptions(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/system/configuration/options`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConfigurationOutputV1>;
  }

  /**
   * @summary Download installer images
   * @param {string} fileName - The name of the installation image
   */
  public getInstallerFile(
    {
      fileName
    } : {
      fileName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(fileName)) {
      throw new Error("'fileName' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/system/installer/${encodeURIComponent(String(fileName))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<string>;
  }

  /**
   * @summary Get the current server license, if any
   */
  public getLicense(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/system/license`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.LicenseStatusOutputV1>;
  }

  /**
   * @summary Used to determine server readiness; available without authentication
   */
  public getOpenPing(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/system/open-ping`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Used to determine server readiness, but only available with authentication
   */
  public getPing(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/system/ping`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Get a list of the emails that have been sent by the server in the past 7 days, up to a maximum of 10,000. Note that non-administrators will only see emails from notifications they manage.
   */
  public getSentEmails(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/system/sent-emails`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.SentEmailsListOutputV1>;
  }

  /**
   * @summary Used to determine the current load the server is experiencing, where a high load indicates a lot of concurrent activity within the server.
   */
  public getServerLoad(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/system/server-load`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ServerLoadOutputV1>;
  }

  /**
   * @summary Used to determine server readiness, telemetry configuration, and other server variables
   * @param {boolean} [includeInternal=false] - whether to include internal statuses
   */
  public getServerStatus(
    {
      includeInternal
    } : {
      includeInternal?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/system/server-status`,

      params: omitBy({
        ['includeInternal']: includeInternal
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ServerStatusOutputV1>;
  }

  /**
   * @summary Get list of commonly used and supported units
   */
  public getSupportedUnits(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/system/supportedUnits`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.SupportedUnitsOutputV1>;
  }

  /**
   * @summary Get a threaddump of the server as a logfile and send the information to the logs
   * @param {number} [maxFrameCount=50] - Maximum frames to output for each thread.
   */
  public getThreadState(
    {
      maxFrameCount
    } : {
      maxFrameCount?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/system/threadState.log`,

      params: omitBy({
        ['maxFrameCount']: maxFrameCount
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Import a license file
   * @param {string} [file]
   */
  public importLicense(
    {
      file
    } : {
      file?: File
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    const formData = new FormData();
    formData.append('file', file as File, file?.name);

    return getAxiosInstance().post(
      APPSERVER_API_PREFIX + '/system/license',
      formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      ...extraHttpRequestConfig,
    }) as AxiosPromise<models.LicenseImporterOutputV1>;
  }

  /**
   * @summary Set the system administrator's contact information
   */
  public setAdministratorContactInformation(
    body: models.AdministratorContactInformationV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/system/contactInformation`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Write one or more configuration options
   */
  public setConfigurationOptions(
    body: models.ConfigurationInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/system/configuration/options`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConfigurationOutputV1>;
  }

  /**
   * @summary Translate an input list to canonical unit of measure strings
   */
  public uOMs(
    body: models.UnitsOfMeasureBatchInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/system/uoms`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UnitsOfMeasureOutputV1>;
  }

  /**
   * @summary Update the attributes of an add-on tool
   * @param {string} id - ID of the add-on tool to update
   */
  public updateAddOnTool(
    body: models.AddOnToolInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/system/configuration/addOnTools/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AddOnToolOutputV1>;
  }

}


export const sqSystemApi = new sqSystemApiClass();
